import React from 'react'
import styled from 'styled-components';
import Thestuxio from '../components/Home/Thestuxio';
import stuxiodata from '../Json/stuxiodata.json';
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionStuxioGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function Stuxiopage() {
  return (
    <Layout>
    <Seo title="stuxio Network" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>Stuxio</h2>
        <SectionStuxioGroup>
          {stuxiodata.stxuios.map((thestuxio) => (
            <Thestuxio
              title={thestuxio.title}
              title01={thestuxio.title01}
              title02={thestuxio.title02}
              title2={thestuxio.title2}
              title3={thestuxio.title3}
              title4={thestuxio.title4}
              title5={thestuxio.title5}
              title6={thestuxio.title6}
              title7={thestuxio.title7}
              title8={thestuxio.title8}
              image={thestuxio.image}
              image2={thestuxio.image2}
              image3={thestuxio.image3}
              image4={thestuxio.image4}
              image5={thestuxio.image5}
              text={thestuxio.text}
              text01={thestuxio.text01}
              text02={thestuxio.text02}
              text2={thestuxio.text2}
              text3={thestuxio.text3}
              text4={thestuxio.text4}
              text5={thestuxio.text5}
              text6={thestuxio.text6}
              text7={thestuxio.text7}
              text8={thestuxio.text8}
              url={thestuxio.url}
            />
          ))}
        </SectionStuxioGroup>
      </div>
    </div>
    </Layout>
  );
}

